import React, { useEffect, useState, useContext } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, navigate } from 'gatsby';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { gql, useQuery } from '@apollo/client';
import { Layout, Modal, ForeignDealNotice } from 'components';
import { Aside, DealRoomHeader, Description, Sidebar, QuestionAnswer } from 'components/DealRoom';
import { IpoNoticeModal } from 'components/IpoNoticeModal'; // Import the component
import { transformSnakeKeysToCamel } from '../utils';
import { DealContext } from '../context/DealContext';
import { WHOLESALE } from '../utils/constants/offerTypes';
import { COUNTRY_OF_RESIDENCE, IP_COUNTRY } from '../utils/constants/cookieNames';
import { Slice } from '../slices/Slice';
import * as styles from './dealRoom.module.scss';
import { setCookie, getCookie } from '../apis';
import { AuthContext } from '../context';

const DealRoom = ({ location, data: staticData }) => {
  const [stickSidebar, setStickSidebar] = useState(false);

  const [headerHeight, setHeaderHeight] = useState(0);

  const [hasClickedInvest, setHasClickedInvest] = useState(false);
  const [hasAgreedTerms, setHasAgreedTerms] = useState(false);

  const { prismicDealRoom } = staticData;

  const { prismicId, uid } = prismicDealRoom;
  const pageData = transformSnakeKeysToCamel(prismicDealRoom.data);

  const { body: sliceData, isEoi, offerType, logo } = pageData;
  const authCountryCookie = getCookie(COUNTRY_OF_RESIDENCE);

  // Get additional deal data from Eq database
  // useQuery is from apollo which uses graphQl query
  const { data: getDbDealQueryRes, loading: dbDealLoading, refetch: refetchDeal } = useQuery(GET_DB_DEAL_DATA, {
    skip: isEoi,
    variables: { prismicId },
  });
  const deal = getDbDealQueryRes?.deal;
  const privateDeal = !!deal?.isPrivate;
  let dealJSONString;

  // If deal is retrieved from DB and the deal is still private,
  // destructure values then create a tile object with values
  // Convert tile object to JSON then store as value in the cookie
  if (deal && privateDeal) {
    const {
      country,
      name: companyName,
      prismicId: id,
      prismicUid,
      expiresAt,
      target,
      targetMax,
      logoUrl,
      imageUrl,
      type,
      description,
      isPrivate,
      key,
    } = deal;

    const visibility = !isPrivate ? 'Public' : 'Private';

    const dbDealToPrismicObject = {
      cta: 'Link',
      tile: {
        document: {
          id: key,
          prismicId: id,
          type: 'deal_room',
          uid: prismicUid,
          data: {
            company_name: { text: companyName },
            country: country?.name,
            end_date: expiresAt,
            eoi_footer: { html: '' },
            hide_raised_amount: true,
            hide_eoi_tag: true,
            hide_timer: false,
            is_maxed_out: false,
            is_eoi: false,
            offer_type: type?.name,
            round_maximum: targetMax,
            round_minimum: target,
            tile_description: { text: description },
            tile_footer: { text: '' },
            tile_image: {
              alt: null,
              type: 'private',
              localFile: null,
              url: imageUrl,
            },
            tile_logo: { alt: null, localFile: null, url: logoUrl },
            visibility,
          },
        },
      },
    };
    dealJSONString = JSON.stringify(dbDealToPrismicObject);
  }

  dayjs.extend(utc);

  const scrollListener = () => {
    // Update sticky sidebar state
    const containerElement = document.getElementById('offer-room-body');

    const { top } = containerElement?.getBoundingClientRect() || {};

    const stick = top <= headerHeight;

    if (stick) {
      setStickSidebar(true);
    } else {
      setStickSidebar(false);
    }
  };

  useEffect(() => {
    const url = window?.location?.href;
    if (url.includes('/offer/') && url.includes('-offer') && dealJSONString) {
      setCookie({ value: dealJSONString, cookieName: `eq_deal_offer_${pageData?.companyName?.text}`, expiry: 7 });
    }
  }, [getDbDealQueryRes]);

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    const header = document.getElementById('header');
    setHeaderHeight(header ? header.offsetHeight : 0);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  useEffect(() => {
    const url = window?.location?.href;
    const zhikEoiCookie = getCookie('eq_zhik_eoi_offer');
    if (authCountryCookie && !zhikEoiCookie) {
      if (url.includes('/offer/zhik') && authCountryCookie !== 'Australia' && authCountryCookie !== 'New Zealand') {
        navigate('/offer/zhik-eu');
      } else if (
        (url.includes('/offer/zhik-eu') && authCountryCookie === 'Australia') ||
        authCountryCookie === 'New Zealand'
      ) {
        navigate('/offer/zhik');
      }
    }

    if (url.includes('/offer/zhik')) {
      setCookie({ value: prismicId, cookieName: 'eq_zhik_eoi_offer' });
    }
  }, []);

  const isWholesale = offerType === WHOLESALE;
  const displayQa = !isEoi && !isWholesale && Boolean(getDbDealQueryRes?.deal?.questions);

  const layoutClassnames = {
    footer: styles.footer,
    wrapper: styles.wrapper,
  };

  const descriptionClassnames = {
    offerRoomContentBlock: styles.offerRoomContentBlock,
    offerRoomKeyPoint: styles.offerRoomKeyPoint,
    tooltip: styles.tooltip,
    visible: styles.visible,
  };

  return (
    <DealContext.Provider
      value={{
        prismicDealData: {
          ...pageData,
          prismicId,
          uid,
        },
        dbDealData: getDbDealQueryRes?.deal,
        dbDealLoading,
        hasClickedInvest,
        setHasClickedInvest,
        hasAgreedTerms,
        setHasAgreedTerms,
        refetchDeal,
      }}
    >
      <Layout
        location={location}
        pageData={{ ...pageData, defaultMetaTitle: `${pageData.companyName.text} | Equitise` }}
        bodyClassName={styles.offerRoomPage}
        inheritedClassnames={layoutClassnames}
        prismicId={prismicId}
        logo={logo}
      >
        <section className="offer-room">
          <ForeignDealNotice prismicDealRoom={prismicDealRoom} />
          {/* <IpoNoticeModal prismicDealRoom={prismicDealRoom} /> */}
          <IpoNoticeModal />
          <DealRoomHeader />
          <div className={styles.wrapper}>
            <section className={styles.offerRoomBody} id="offer-room-body">
              <Aside />
              <section className={styles.offerRoomContent}>
                <Description inheritedClassnames={descriptionClassnames} />
                {sliceData?.map(
                  slice =>
                    slice.id &&
                    slice.slice_type && <Slice key={slice.id} data={slice} location={location} template="dealRoom" />
                )}
                {displayQa && <QuestionAnswer location={location} />}
              </section>
              <Sidebar headerHeight={headerHeight} sticky={stickSidebar} />
            </section>
          </div>
        </section>
      </Layout>
    </DealContext.Provider>
  );
};

export default withPrismicPreview(DealRoom);

const GET_DB_DEAL_DATA = gql`
  query getDealByPrismicId($prismicId: String) {
    deal(prismicId: $prismicId) {
      id
      key
      name
      description
      expiresAt
      country {
        id
        name
      }
      target
      targetMax
      imageUrl
      logoUrl
      prismicId
      prismicUid
      isPrivate
      type {
        id
        name
      }
      documents {
        id
        url {
          path
          expiry
        }
      }
      metrics {
        isLive
        hasMaxxedNzRetailFunding
        hasTargetMax
        raisedAmount
      }
      members {
        id
      }
      memberRoles {
        roleId
        role {
          id
          name
          label
        }
        user {
          id
          email
        }
      }
      creator {
        id
        email
      }
      questions {
        id
        title
        description
        author {
          id
          firstName
          lastName
          email
          roles {
            id
            label
          }
        }
        createdAt
        answer {
          id
          title
          description
          author {
            id
            firstName
            lastName
            roles {
              id
              name
              label
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  query DealRoomBySlug($uid: String!) {
    prismicDealRoom(uid: { eq: $uid }) {
      id
      uid
      prismicId
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        header_description {
          html
        }
        tile_description {
          html
        }
        eoi_footer {
          html
        }
        lead_manager {
          text
        }
        terms {
          html
        }
        end_date(locale: "utc")
        country
        offer_type
        price_per_share
        currency
        round_maximum
        round_minimum
        visibility
        is_eoi
        hide_timer
        hide_raised_amount
        key_information {
          field_name {
            text
          }
          number_value
          text_value {
            text
          }
          tooltip {
            text
          }
        }
        rewards {
          threshold
          description {
            html
          }
          header {
            text
          }
          image {
            alt
            url
            localFile {
              extension
              childImageSharp {
                gatsbyImageData(width: 400, placeholder: BLURRED)
              }
            }
          }
        }
        logo {
          alt
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300, placeholder: BLURRED)
            }
          }
        }
        social_links {
          link {
            url
          }
          platform
        }
        title {
          text
        }
        description {
          html
        }
        company_name {
          text
        }
        tile_description {
          html
        }
        featured_image {
          alt
          url
          localFile {
            extension
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: BLURRED)
            }
          }
        }
        featured_video {
          url
        }
        exposure_period
        body {
          ...AnchorPointDealRoomFragment
          ...AccordionDealRoomFragment
          ...TextBlockDealRoomFragment
          ...QuoteDealRoomFragment
          ...CarouselDealRoomFragment
          ...KeyPointsDealRoomFragment
          ...TeamDealRoomFragment
          ...TableSectionDealRoomFragment
          ...InvestorRewardsDealRoomFragment
          ...DocumentsDealRoomFragment
          ...VideoFieldDealRoomFragment
          ...FollowSocialsDealRoomFragment
        }
      }
    }
    ...WarningStatementsFragment
  }
`;
