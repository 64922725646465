import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Image, Layout, Link, SocialLinks, Video } from 'components';
import { graphql } from 'gatsby';
import React from 'react';
import { formatDate, stripHyphens } from 'utils';
import { Cta } from '../slices/Cta/index';
import * as styles from './blogPost.module.scss';
import { Slice } from '../slices/Slice';
import Section from '../components/Section';

const BlogPost = ({ location, data: staticData }) => {
  const { prismicBlogPost } = staticData;
  const {
    data: pageData,
    first_publication_date: firstPublicationDate,
    last_publication_date: lastPublicationDate,
    formattedPublicationDate: prismicDate,
  } = prismicBlogPost;
  const {
    title,
    categories,
    author,
    author_position: authorPosition,
    content,
    date_published: datePublished,
    featured_image: featuredImage,
    uid,
    body: sliceData,
    featured_video: featuredVideo,
  } = pageData;

  const url = location?.href;
  const postTitle = title?.text;

  const socialLinks = [
    {
      platform: 'LinkedIn',
      link: {
        url: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${postTitle}&source=LinkedIn`,
      },
    },
    {
      platform: 'Facebook',
      link: {
        url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      },
    },
    {
      platform: 'Twitter',
      link: {
        url: `https://twitter.com/intent/tweet?text=Check%20out%20${postTitle}%20on%20Equitise%20${url}`,
      },
    },
  ];

  const ctaData = {
    data: {
      primary: {
        tagline: {
          text: 'First in the know',
        },
        title: {
          text: 'Get first dibs on new offers',
        },
      },
    },
  };

  const sliceProps = { pageData };

  return (
    <Layout
      article
      location={location}
      pageData={{
        ...pageData,
        defaultMetaTitle: `${title.text} | Equitise`,
        firstPublicationDate,
        lastPublicationDate,
      }}
      bodyClassName={styles.blogPostPage}
    >
      <section className={styles.blogPost}>
        <Section className={styles.blogPostHeader} containerClassName={`${styles.wrapper} wrapper`} as="header">
          <p className={styles.blogPostDate}>{formatDate(datePublished || prismicDate)}</p>
          {title && <h1 className={styles.blogPostTitle}>{title.text}</h1>}
          <p>
            {author && author.text && `${author.text}, `}
            {authorPosition && authorPosition.text}
          </p>
          <div className={styles.blogPostCategories}>
            {categories &&
              categories.map(({ category: { document } }) => {
                const { uid: category } = document || {};
                return (
                  category && (
                    <div className={styles.blogPostCategory} key={category}>
                      <Link to={`/blog/${category}`}>{stripHyphens(category)}</Link>
                    </div>
                  )
                );
              })}
          </div>
        </Section>
        <Section className={styles.blogPostImageContainer} containerClassName={`${styles.wrapper} wrapper`} as="div">
          {featuredVideo.url ? (
            <Video src={featuredVideo.url} className={styles.blogPostImage} />
          ) : (
            <Image image={featuredImage} className={styles.blogPostImage} alt={featuredImage?.alt || title.text} />
          )}
        </Section>
        <Section className={styles.blogPostContentContainer} containerClassName={`${styles.wrapper} wrapper`} as="div">
          <SocialLinks inheritedClassname={styles.shareLinks} socialLinks={socialLinks} />
          <div dangerouslySetInnerHTML={{ __html: content.html }} className={styles.blogPostContent} />
          <div className={styles.blogSlices}>
            {sliceData.map(slice => (
              <Slice key={slice.id} data={slice} {...sliceProps} />
            ))}
          </div>
        </Section>
      </section>
      <Cta inheritedClassname={styles.ctaSection} {...ctaData} />
    </Layout>
  );
};

export default withPrismicPreview(BlogPost);

export const pageQuery = graphql`
  query BlogPostBySlug($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      formattedPublicationDate: last_publication_date(formatString: "DD MM YYYY")
      _previewable
      data {
        categories {
          category {
            document {
              ... on PrismicCategory {
                uid
              }
            }
          }
        }
        date_published
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        title {
          text
        }
        featured_video {
          id
          target
          uid
          url
        }
        featured_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2400, placeholder: BLURRED)
            }
            extension
          }
          url
        }
        author {
          text
        }
        author_position {
          text
        }
        content {
          html
        }
        body {
          ...TextBlockBlogPostFragment
          ...ImageFieldBlogPostFragment
          ...VideoFieldBlogPostFragment
          ...TableSectionBlogPostFragment
        }
      }
    }
  }
`;
