// extracted by mini-css-extract-plugin
export var blogPost = "blogPost-module--blog-post--tEe-J";
export var blogPostCategories = "blogPost-module--blog-post-categories--vuEJt";
export var blogPostCategory = "blogPost-module--blog-post-category--a4Exr";
export var blogPostContent = "blogPost-module--blogPostContent--gifHC";
export var blogPostContentContainer = "blogPost-module--blog-post-content-container--cfYjb";
export var blogPostDate = "blogPost-module--blog-post-date--JI-QL";
export var blogPostHeader = "blogPost-module--blog-post-header--ZigyT";
export var blogPostImage = "blogPost-module--blog-post-image--vUOT-";
export var blogPostImageContainer = "blogPost-module--blog-post-image-container--BoNEK";
export var blogPostPage = "blogPost-module--blog-post-page--ijouP";
export var blogPostTitle = "blogPost-module--blog-post-title--SZ7ua";
export var blogSlices = "blogPost-module--blog-slices--uJSZT";
export var ctaSection = "blogPost-module--cta-section--Cys-E";
export var gatsbyImage = "blogPost-module--gatsby-image--wcsqV";
export var gatsbyImageWrapper = "blogPost-module--gatsby-image-wrapper--xXvWn";
export var gatsbyImageWrapperConstrained = "blogPost-module--gatsby-image-wrapper-constrained--3wV1O";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var shareLinks = "blogPost-module--share-links--kG1Ie";
export var video = "blogPost-module--video--4z6Ph";
export var wrapper = "blogPost-module--wrapper--nEKz1";