import React from 'react';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { navigate } from 'gatsby';
import BlogPost from '../templates/blogPost';
import DealRoom from '../templates/dealRoom';
import Page from '../templates/page';

const UnpublishedPage = props => {
  const IS_BROWSER = typeof window !== 'undefined';
  if (!IS_BROWSER) return null;
  const previewData = window.__PRISMIC_PREVIEW_DATA__;
  if (!previewData) {
    navigate('/404');
    return null;
  }
  const { prismicBlogPost, prismicDealRoom } = previewData;
  // => Perform any logic from previewData to determine the correct page or template component to use.
  if (prismicBlogPost) return <BlogPost {...props} data={{ prismicBlogPost: { ...previewData.prismicBlogPost } }} />;
  if (prismicDealRoom) return <DealRoom {...props} data={{ prismicDealRoom: { ...previewData.prismicDealRoom } }} />;
  return <Page {...props} data={{ page: { ...previewData.prismicPage } }} />;
};

export default withPrismicUnpublishedPreview(UnpublishedPage);
