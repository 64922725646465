// extracted by mini-css-extract-plugin
export var accordionBlock = "dealRoom-module--accordion-block--S3jxS";
export var accordionSection = "dealRoom-module--accordion-section--o8UmI";
export var button = "dealRoom-module--button--bvKZG";
export var carousel = "dealRoom-module--carousel--TQBqp";
export var footer = "dealRoom-module--footer--06rPh";
export var gatsbyImage = "dealRoom-module--gatsby-image--p83UO";
export var modalText = "dealRoom-module--modalText--ep7wA";
export var noPadding = "dealRoom-module--no-padding--Kx51I";
export var offerRoomBody = "dealRoom-module--offer-room-body--XIYMn";
export var offerRoomContent = "dealRoom-module--offer-room-content--xaFmv";
export var offerRoomContentBlock = "dealRoom-module--offer-room-content-block--8VPDt";
export var offerRoomKeyPoint = "dealRoom-module--offer-room-key-point--l1X9E";
export var offerRoomPage = "dealRoom-module--offer-room-page--CQUJq";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var tooltip = "dealRoom-module--tooltip--OmdjQ";
export var visible = "dealRoom-module--visible--JatUa";
export var wrapper = "dealRoom-module--wrapper--9xlrN";